/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable strict */
/* eslint-disable func-names */

//import "bootstrap/js/dist/util";

// Korvattu nämä standalone bootsrap.bundle.js:llä kts. duo_enquene_scripts.php
// Webpack pakkaa koko jqueryn mukaan
// import "bootstrap/js/src/tab";
// import "bootstrap/js/src/modal";

// import {initCategoryArticle} from "./tp-tab";

(function($) {
  "use strict";

  $(function() {
    // Variablet
    var $submenu_switch = $(".open-submenu");

    // Valikon submenun avaus
    function open_submenu() {
      $submenu_switch.click(function(event) {
        event.preventDefault();
        var $submenu = $(this).parent().next(".sub-menu");
        $submenu.slideToggle(150);
        $(this)
          .parent().parent()
          .toggleClass("submenu-active");
      });
    }

    // Funktiot käyntiin

      // initCategoryArticle($);
      if ($submenu_switch.length) {
        open_submenu();

        // Tästä alaspäin on purkkaa
        // if (
        //   $(".sub-menu")
        //     .find(".nav-label:first")
        //     .find("ul").css('display') === 'block'
        // ) {
        //   $(".sub-menu")
        //     .find(".nav-label:first")
        //     .addClass("submenu-active");
        // }
      }

  });
})(jQuery);







